<template>
  <div class="form-block">

    <div class="form-block__wrapper">

      <!-- Main Heading for Flow -->
      <div>
        <h1 class="is-size-3 has-text-weight-black form-block__heading">
          Forgot your password?
        </h1>
        <p class="is-size-7 has-text-grey form-block__desc">
          No worries, it happens!
          Just enter your email and we will send you a link to set up a new one
        </p>
      </div>
        <!-- Back to Login -->
        <go-back
          :view="this.$t('myaccount.menu.login')"
          :text="this.$t('myaccount.menu.back-to-login')"
        ></go-back>
        <!-- The User Email form for Forgotten Password -->
        <form @submit.prevent="handleSubmit">
          <!-- Email Field -->
          <email-field
            v-on:valid="validEmail = $event"
            v-on:input="emailValue = $event"
          ></email-field>
          <p
            class="
              message
              is-size-7
              has-text-left
              has-text-weight-medium
            "
            :class="!submissionError && 'is-invisible'"
          >
            <!-- The validation message -->
            <span>{{ submissionError }}</span>
          </p>
          <!-- Form Submit Button -->
          <button
            class="
              button
              is-medium
              is-fullwidth
              has-text-weight-semibold
              has-text-white
            "
            :class="
              handleDisable(this.emailValue) ?
                'has-background-grey-lighter' : 'valid'
            "
            :disabled='handleDisable(this.emailValue) || inProgress'
          >
            <span class="is-size-6">
              Confirm
            </span>
          </button>

        </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import GoBack from './components/GoBack'
import EmailField from './components/EmailField'

export default {
  components: { EmailField, GoBack },

  data: () => ({
    // User email validation
    emailValue: '',
    validEmail: false,

    inProgress: false,

    // Form was submitted and failed
    submissionError: false
  }),

  methods: {
    ...mapActions('account/', ['verification']),

    /**
     * Dispatch the verification code email and redirect to the reset form.
     */
    async handleSubmit () {
      // if email is in the form a@a.com
      if (!this.validEmail) {
        this.submissionError = 'Email is not valid'
        return
      }
      this.inProgress = true
      await this.verification({
        email: this.emailValue
      })
        .then(() => btoa(this.emailValue)) // Prevents errors if bota return an exception.
        .then(() => {
          this.$messages.removeShown('auth')
          return this.$router.push({
            name: 'reset-password',
            params: {
              email: btoa(this.emailValue)
            }
          }, () => {
            this.$messages.success(this.$t('myaccount.notification-bar.code-sent'), 'auth')
            this.$emit('has-notification', true)
          })
        })
        .catch(() => {
          this.inProgress = false
          this.$messages.removeShown('auth')
          this.$router.push({
            name: 'login'
          }, () => {
            this.$messages.danger(
              this.$t('myaccount.notification-bar.error-contact-us'),
              'auth',
              false
            )
            this.$emit('has-notification', true)
          })
        })
    },

    handleDisable (email) {
      return email.length === 0
    }
  },

  mounted () {
    this.$messages.removeShown('auth')
    this.$emit('has-notification', false)
  }
}
</script>

<style lang="sass" scoped>

.form-block
  padding: 10px 0
  &__wrapper
    margin: 62px auto 100px auto
    max-width: 464px
    @media (min-width: 768px)
      background-color: white
      padding: 32px 72px
  &__heading
    text-align: center
    margin-bottom: 24px
  &__desc
    text-align: center

p.message
  color: #FF3223
  background: transparent
  height: 0.2rem
  line-height: 0.8rem
  margin-bottom: 0.2rem

  & > span::before
    content: ''
    width: 16px
    height: 8px
    margin-left: 0.2rem
    display: inline-block
    background: url(/static/images/auth_error.svg) no-repeat left bottom

p
  margin: 1rem 0

form
  button
    margin: 1rem 0
    border-radius: 4px

    &.valid
      background: #FF6441 !important

      &:hover
        background: #FF785A !important

</style>
